import { IPagingQueryParams, ISortQuery } from '@inmarsat-itcloudservices/ui';
import { AccountGroup } from './account.model';

export interface IChangeRequest {
  _id: string;
  requestType: IChangeRequestType;
  requestSummary: IChangeRequestSummary;
  entityType: IChangeRequestEntityType;
  entityId: string;
  entityName: string;
  assigned: boolean;
  assignmentGroup: IChangeRequestAssignmentGroup;
  status: IChangeRequestStatus;
  startDate: Date;
  movedTo?: string;
  movedFrom?: string;
  assignedOn?: Date;
  accountGroup?: AccountGroup; //Send accountGroup value if entityType value is Account, otherwise leave it as empty.
  endDate?: Date;
}

export enum IChangeRequestStatus {
  APPROVED = 'Approved',
  PENDING_APPROVAL = 'Pending Approval',
  REJECTED = 'Rejected',
  SEND_BACK = 'Sent Back'
}

export interface IChangeRequestDetail extends IChangeRequest {
  assignee: string;
  requester?: string;
  comments?: IChangeRequestComments;
  assignedOn?: Date;
  payload?: Record<string, unknown>;
  moveTo?: string;
}

export interface IChangeRequestQuery extends ISortQuery, IPagingQueryParams {
  status?: IChangeRequestStatus;
  requestType?: IChangeRequestType;
  entityType?: IChangeRequestEntityType;
  assignee?: string;
  requester?: string;
  entityId?: string;
  entityName?: string;
  assigned?: boolean;
  accountGroup?: AccountGroup;
}

export interface IChangeRequestComments {
  createdDate: string;
  createdBy: string;
  comment: string;
}

export interface IChangeRequestConfig {
  requestType: IChangeRequestType;
  requestSummary?: IChangeRequestSummary;
  entityType?: IChangeRequestEntityType;
  entityId?: string;
  entityName?: string;
  accountGroup?: AccountGroup;
  assignmentGroup?: IChangeRequestAssignmentGroup;
  movedFrom?: string;
  comment?: string;
  payload?: Record<string, unknown>;
  legalEntityCode?: string;
  legalEntityName?: string;
}
// change request actions on portal
export enum IChangeRequestOptions {
  APPROVE = 'Approve',
  SEND_BACK = 'Sent Back',
  REJECT = 'Reject',
  RESUBMIT = 'Re submit',
  ASSIGN_IT_TO_ME = 'Assign',
  UNASSIGN_IT_TO_ME = 'Unassign it to me',
  CREATE = 'Create'
}
// Backend endpoint name
export enum IChangeRequestActions {
  APPROVE = 'approve',
  REVISE = 'revise',
  REJECT = 'reject',
  RESUBMIT = 'resubmit',
  ASSIGN = 'assign',
  UNASSIGN = 'unassign',
  CREATE = 'create'
}

export enum IChangeRequestAssignmentGroup {
  CREDIT = 'Credit',
  SALE = 'Sale'
}

export const changeRequestOptions = {
  APPROVE: {
    label: IChangeRequestOptions.APPROVE,
    apiAction: IChangeRequestActions.APPROVE
  },
  SEND_BACK: {
    label: IChangeRequestOptions.SEND_BACK,
    apiAction: IChangeRequestActions.REVISE
  },
  REJECT: {
    label: IChangeRequestOptions.REJECT,
    apiAction: IChangeRequestActions.REJECT
  },
  ASSIGN_IT_TO_ME: {
    label: IChangeRequestOptions.ASSIGN_IT_TO_ME,
    apiAction: IChangeRequestActions.ASSIGN
  },
  UNASSIGN_IT_TO_ME: {
    label: IChangeRequestOptions.UNASSIGN_IT_TO_ME,
    apiAction: IChangeRequestActions.UNASSIGN
  },
  RESUBMIT: {
    label: IChangeRequestOptions.RESUBMIT,
    apiAction: IChangeRequestActions.RESUBMIT
  },
  CREATE: {
    label: IChangeRequestOptions.CREATE,
    apiAction: IChangeRequestActions.CREATE
  }
};

export interface IChangeRequestAction {
  requestId: string;
  action: IChangeRequestActions;
  comment: string;
  payload?: Record<string, unknown>;
  movedTo?: string;
  movedFrom?: string;
}

export interface IChangeRequestPayloadObject {
  changeRequest: IChangeRequestConfig;
}

export enum IChangeRequestSummary {
  PAYMENT_TERMS = 'Payment terms',
  END_DATE = 'endDate',
  NAME = 'Name',
  OTHER = 'Other',
  CREATE_CLE = 'Create CLE',
  CREATE_ACCOUNT = 'Create Account',
  MOVE_AR = 'Move account under a new legal entity',
  MOVE_BILLING = 'Move to a new AR account under different legal entity'
}

export enum IChangeRequestEntityType {
  ACCOUNT = 'Account',
  CLE = 'Customer legal entity'
}

export enum IChangeRequestType {
  CREATE = 'Create',
  UPDATE = 'Update',
  MOVE = 'Move'
}

export interface IChangeRequestActionItems {
  requester?: number;
  pendingApprovalAssignedCR?: number;
  myIncompleteCount?: number;
  myDraftCount?: number;
}

export enum AccountActionRelatedChangeRequest {
  UPDATE_ACCOUNT_DETAILS = 'updateAccountDetails',
  UPDATE_ACCOUNT_INFO_AND_CONTACT = 'updateAccountAddressAndContact'
}
