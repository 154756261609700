import { Injectable } from '@angular/core';
import {
  addNotificationItem,
  concatSpinner,
  createLoadTask,
  createSuccessNotification,
  getStaticContent
} from '@inmarsat-itcloudservices/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ChangeRequestApiService } from '@app/api/change-request/change-request-api.service';
import { mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MODAL_CONFIGURATION, staticContent, DEFAULT_SUB_TABLE_PAGE_SIZE, DEFAULT_PAGE_SIZE } from '@app/app.constants';
import { ChangeRequestModalComponent } from '@app/change-request/containers/change-request-modal/change-request-modal.component';
import { forkJoin, of } from 'rxjs';
import { CLEApiService } from '@app/api/cle/cle-api.service';
import { AccountApiService } from '@app/api/account/account-api.service';
import { Store } from '@ngrx/store';
import { IChangeRequestActions, IChangeRequestEntityType } from '../../shared/models/change-request.model';
import { getAccountDetails } from '../account/account.selectors';
import { IState } from '..';
import * as ChangeRequestActions from './change-request.actions';

@Injectable()
export class ChangeRequestEffects {
  public loadChangeRequestList$ = createLoadTask(({ query }) => this.changeRequestApi.getChangeRequestList(query), {
    actions: this.actions$,
    ofType: ChangeRequestActions.loadChangeRequest,
    onSuccess: ChangeRequestActions.loadChangeRequestSuccess
  });

  public getAcitiveChildAccounts$ = createLoadTask(
    ({ query, parentAccountNumber }) => this.accountApi.getChildAccounts(query, parentAccountNumber),
    {
      actions: this.actions$,
      ofType: ChangeRequestActions.getAcitiveChildAccounts,
      onSuccess: ChangeRequestActions.getAcitiveChildAccountsSuccess
    }
  );

  public loadChangeRequestDetails$ = createLoadTask(
    ({ requestId, legalEntityCode, accountCode }) =>
      forkJoin({
        changeRequestDetails: this.changeRequestApi.getChangeRequestDetails(requestId),
        associatedCLE: legalEntityCode === undefined ? of(null) : this.cleApi.getCLE(legalEntityCode),
        associatedAccount: accountCode === undefined ? of(null) : this.accountApi.getAccountDetails(accountCode)
      }),
    {
      actions: this.actions$,
      ofType: ChangeRequestActions.detailLoad,
      onSuccess: ({ changeRequestDetails, associatedCLE, associatedAccount }) =>
        ChangeRequestActions.detailLoadSuccess(changeRequestDetails, associatedCLE, associatedAccount)
    }
  );

  public openChangeRequestModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChangeRequestActions.openChangeRequestModal),
        tap(() => {
          return this.modalService.show(ChangeRequestModalComponent, {
            ...MODAL_CONFIGURATION,
            class: 'inm-modal-gx-export',
            initialState: {}
          });
        })
      ),
    { dispatch: false }
  );

  public sendResponseOfChangeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeRequestActions.sendResponseOfChangeRequest),
      concatSpinner(({ query }) =>
        this.changeRequestApi.changeRequestActions(query).pipe(
          withLatestFrom(this.store.select(getAccountDetails)),
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          mergeMap((action) => {
            const actions = [];
            actions.push(
              ChangeRequestActions.detailLoad(query.requestId, undefined, undefined),
              ChangeRequestActions.openChangeRequestModalSuccess()
            );

            if (query.movedTo) {
              actions.push(
                ChangeRequestActions.loadChangeRequest({
                  entityId: action[1].accountNumber,
                  offset: 0,
                  limit: DEFAULT_PAGE_SIZE
                })
              );
            }

            switch (query.action) {
              case IChangeRequestActions.APPROVE:
                actions.unshift(
                  addNotificationItem(
                    createSuccessNotification(
                      getStaticContent('change_request.change_request_action.approve_change_request', staticContent)
                    )
                  )
                );
                return actions;
              case IChangeRequestActions.REJECT:
                actions.unshift(
                  addNotificationItem(
                    createSuccessNotification(
                      getStaticContent('change_request.change_request_action.deny_change_request', staticContent)
                    )
                  )
                );
                return actions;
              case IChangeRequestActions.REVISE:
                actions.unshift(
                  addNotificationItem(
                    createSuccessNotification(
                      getStaticContent('change_request.change_request_action.send_back_change_request', staticContent)
                    )
                  )
                );
                return actions;
              case IChangeRequestActions.ASSIGN:
                actions.unshift(
                  addNotificationItem(
                    createSuccessNotification(
                      getStaticContent('change_request.change_request_action.assign_it_to_me', staticContent)
                    )
                  )
                );
                return actions;
              case IChangeRequestActions.UNASSIGN:
                actions.unshift(
                  addNotificationItem(
                    createSuccessNotification(
                      getStaticContent('change_request.change_request_action.unassign_it_to_me', staticContent)
                    )
                  )
                );
                return actions;
              // condition of resubmit
              default:
                query.payload && ['endDate'].every((key) => Object.keys(query.payload).includes(key))
                  ? actions.unshift(
                      addNotificationItem(
                        createSuccessNotification(
                          getStaticContent(
                            'change_request.change_request_action.resubmit_change_request_single_field',
                            staticContent
                          )
                        )
                      )
                    )
                  : actions.unshift(
                      addNotificationItem(
                        createSuccessNotification(
                          getStaticContent(
                            'change_request.change_request_action.resubmit_change_request',
                            staticContent
                          )
                        )
                      )
                    );
                return actions;
            }
          })
        )
      )
    )
  );

  public openChangeRequestModalSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChangeRequestActions.openChangeRequestModalSuccess),
        tap(() => {
          return this.modalService.hide();
        })
      ),
    { dispatch: false }
  );

  public loadActionItems$ = createLoadTask(
    ({ hasCreditRole, hasSalesRole, query, myIncompleteCLE, myDraftAccount }) =>
      forkJoin({
        requesterResponse: hasSalesRole ? this.changeRequestApi.getRequester() : of(null),
        pendingApprovalAssignedCRResponse: hasCreditRole ? this.changeRequestApi.getChangeRequestList(query) : of(null),
        myIncompleteCount: hasSalesRole ? this.cleApi.getCLEs(myIncompleteCLE) : of(null),
        myDraftAccount: hasSalesRole ? this.accountApi.getAccountList(myDraftAccount) : of(null)
      }),
    {
      actions: this.actions$,
      ofType: ChangeRequestActions.loadActionItems,
      onSuccess: ({ requesterResponse, pendingApprovalAssignedCRResponse, myIncompleteCount, myDraftAccount }) => {
        return ChangeRequestActions.loadActionItemsSuccess({
          requester: requesterResponse ? requesterResponse.count : 0,
          pendingApprovalAssignedCR: pendingApprovalAssignedCRResponse ? pendingApprovalAssignedCRResponse.count : 0,
          myIncompleteCount: myIncompleteCount ? myIncompleteCount.count : 0,
          myDraftCount: myDraftAccount ? myDraftAccount.count : 0
        });
      }
    }
  );

  public createChangeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeRequestActions.createChangeRequest),
      concatSpinner(({ changeRequest, changeRequestINMAAndINMB }) => {
        const apiCalls = [this.changeRequestApi.createChangeRequest(changeRequest)];
        if (changeRequestINMAAndINMB) {
          apiCalls.push(this.changeRequestApi.createChangeRequest(changeRequestINMAAndINMB));
        }
        return forkJoin(apiCalls).pipe(
          mergeMap(() => {
            const basicActions = [];
            basicActions.push(
              ChangeRequestActions.openChangeRequestModalSuccess(),
              ChangeRequestActions.loadChangeRequest({
                entityId: changeRequest.entityId,
                offset: 0,
                limit: DEFAULT_SUB_TABLE_PAGE_SIZE
              }),
              changeRequest.entityType === IChangeRequestEntityType.CLE
                ? addNotificationItem(
                    createSuccessNotification(
                      getStaticContent('customers.cle.details.request_approval.success_notification', staticContent)
                    )
                  )
                : addNotificationItem(
                    createSuccessNotification(
                      getStaticContent('accounts.details.request_approval.success_notification', staticContent)
                    )
                  )
            );
            return basicActions;
          })
        );
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<IState>,
    private readonly changeRequestApi: ChangeRequestApiService,
    private readonly cleApi: CLEApiService,
    private readonly accountApi: AccountApiService,
    protected modalService: BsModalService
  ) {}
}
