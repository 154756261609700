/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import {
  getHasPermission,
  STATIC_CONTENT_CONTEXT,
  STATIC_CONTENT_PAYLOAD,
  IReferenceOption,
  getStaticContent,
  IconName,
  IconColor,
  getOBFFeedTypeOptions
} from '@inmarsat-itcloudservices/ui';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { staticContent } from '@app/app.constants';
import { IState } from '@app/shared-store';
import * as AccountActions from '@shared-store/account/account.actions';
import {
  getAccountDetails,
  getChildAccounts,
  getParentAccount,
  getParentAccountsList,
  getDealer,
  getShipOwner,
  getManagementCompanyInfo,
  getPartnerInfoByAccount,
  getContractPartnerInfo,
  getMoveToCLEOrAccountDetails
} from '@shared-store/account/account.selectors';
import {
  AccountGroup,
  IAccountDetails,
  IAccountQuery,
  IMoveBPAccountPayload,
  MoveBPAccountFields,
  AccountStatus
} from '@shared/models/account.model';
import { AuthAction, AuthResource } from '@shared/models/auth.model';
import {
  setupOutboundFeedPayload,
  combineOutboundFeedWithSameFeedFormatAndType
} from 'src/app/outbound-feed/outbound-feed-helpers';
import { OutboundFeedStatus, MandatoryOutboundFeed } from 'src/app/shared/models/outbound-feed.model';
import * as ChangeRequestActions from '@shared-store/change-request/change-request.actions';
import {
  IChangeRequestEntityType,
  IChangeRequestType,
  IChangeRequestSummary,
  IChangeRequestAssignmentGroup,
  IChangeRequestOptions,
  IChangeRequestStatus,
  IChangeRequestPayloadObject
} from '@app/shared/models/change-request.model';
import { getChangeRequestList } from '@app/shared-store/change-request/change-request.selectors';
import { difference } from 'ramda';
import { AccountAddOutboundFeedStepComponent } from '../account-add-outbound-feed-step/account-add-outbound-feed-step.component';

const TOOLIP_MESSAGE = getStaticContent('accounts.create.obf.tooltip_message', staticContent);
const CREATE_MOVE_CHANGE_REQUEST = getStaticContent(
  'accounts.details.child_account_move.create_move_change_request',
  staticContent
);
const RESUBMIT_MOVE_CHANGE_REQUEST = getStaticContent(
  'accounts.details.child_account_move.resubmit_move_change_request',
  staticContent
);

@Component({
  selector: 'inm-child-account-move-modal',
  templateUrl: './child-account-move-modal.component.html',
  styleUrls: ['./child-account-move-modal.component.scss'],
  providers: [
    {
      provide: STATIC_CONTENT_CONTEXT,
      useValue: 'accounts.details.child_account_move'
    },
    {
      provide: STATIC_CONTENT_PAYLOAD,
      useValue: staticContent
    }
  ]
})
export class ChildAccountMoveModalComponent implements OnInit, OnDestroy {
  public accountMoveForm: UntypedFormGroup;

  public parentAccountsList: {
    loaded: boolean;
    items: IAccountDetails[];
  };

  public expandedPanel = {
    accountMoveFrom: false,
    partnerForm: false,
    outboundFeedForm: false
  };

  public accountDetails: IAccountDetails;

  public parentAccount: IAccountDetails;

  public siblingAccountCount: number;

  public allowChildAccountMove: boolean;

  public isSearching = false;

  public selectCLECode: string;

  public newARAccountCtrl = new UntypedFormControl(null, [Validators.required]);

  public dealerCtrl = new UntypedFormControl(null);

  public readonly shipOwnerCtrl = new UntypedFormControl(null);

  public readonly contractPartyCtrl = new UntypedFormControl(null);

  public readonly managementPartyCtrl = new UntypedFormControl(null);

  public dealerList: any;

  private dealerListFirstLoad = true;

  public shipOwnerList: any;

  private shipOwnerListFirstLoad = true;

  public contractPartyList: any;

  private contractPartyListFirstLoad = true;

  public managementPartyList: any;

  private managementPartyListFirstLoad = true;

  public partnerList: any;

  private canMoveARAccount: boolean;

  private canMoveBPAccount: boolean;

  public accountGroupList = AccountGroup;

  public changeRequestOptions = IChangeRequestOptions;

  public feedType: IReferenceOption[];

  public showWarningPrompt = false; // show checkbox when user moves BP account in different CLE.

  public showUnmatahedSalesOrgs = false; // Show Unmatched sales orgs combination message.

  public requiredNewSalesCode = null;

  public isConfirmed = false;

  private readonly parentAccountGroup: string[];

  private readonly subs = new Subscription();

  @ViewChild('outboundFeedForm')
  public outboundFeedForm: AccountAddOutboundFeedStepComponent;

  public readonly iconNames = IconName;

  public readonly iconColors = IconColor;

  public allowedTypesNewOutboundFeeds;

  public showErrorMessage = false;

  public associatedChangeRequestParams;

  public changeRequestRelatedLinks = {
    needAccountMoveRequest: true,
    latestChangeRequestStatus: null,
    latestChangeRequestId: null,
    movedTo: null,
    message: CREATE_MOVE_CHANGE_REQUEST,
    hasApprovedMovedToAccount: false, // used for checking if need inital load approved CLE/account
    approvedAccountInfo: null // Store approved CLE/Account info
  };

  constructor(
    public bsModalRef: BsModalRef,
    private readonly store: Store<IState>
  ) {
    this.accountMoveForm = new UntypedFormGroup({
      newARAccount: this.newARAccountCtrl,
      dealerCode: this.dealerCtrl,
      shipOwner: this.shipOwnerCtrl,
      contractParty: this.contractPartyCtrl,
      managementParty: this.managementPartyCtrl
    });
    this.parentAccountGroup = [];
    this.allowChildAccountMove = false;
  }

  public ngOnInit(): void {
    this.subs.add(
      this.store
        .pipe(getHasPermission(true, [`${AuthResource.CMD_ACCOUNT}:${AuthAction.Move}`]))
        .subscribe((allowed: boolean) => (this.canMoveARAccount = allowed))
    );
    this.subs.add(
      this.store
        .pipe(getHasPermission(true, [`${AuthResource.CMD_ACCOUNT}:${AuthAction.BAMove}`]))
        .subscribe((allowed: boolean) => (this.canMoveBPAccount = allowed))
    );

    this.getApprovedMovedTo();
    this.getLinkLabel();

    this.subscribeToAccountDetails();
    this.subscribeToSiblingAccountList();
    this.subscribeToParentAccountList();

    this.subscribeTonewARAccountCtrl();
    this.updatePartnerInVesselAccount();

    this.getFeedTypeList();
  }

  public searchAccount(search: string): void {
    this.isSearching = true;
    // If user has move account to dif CLE permission, user can move account to dif CLE without CR on Incomplete/Inactive account
    if (this.canMoveARAccount && !this.changeRequestRelatedLinks.needAccountMoveRequest) {
      const query = {
        accountGroup: this.parentAccountGroup,
        multiSearch: true,
        name: search,
        accountNumber: search
      };
      this.store.dispatch(AccountActions.loadParentAccounts(query));
    }
    if (this.canMoveBPAccount) {
      // Allow to move BP account in same CLE
      const query = {
        legalEntityCode: this.accountDetails.legalEntityCode,
        accountGroup: this.parentAccountGroup,
        multiSearch: true,
        name: search,
        accountNumber: search
      };
      this.store.dispatch(AccountActions.loadParentAccountsUnderSameCLE(query));
    }
  }

  public confirm(): void {
    if (this.accountMoveForm.valid && (this.outboundFeedForm?.form.valid || this.outboundFeedForm?.form.disabled)) {
      let partnerPayload = this.getChangedPartnerPayload();
      if (partnerPayload) {
        partnerPayload = {
          ...partnerPayload,
          accountNumber: this.accountDetails.accountNumber
        };
      }
      // moved BP account to new AR account under same/different CLE based on user's role

      if (this.selectCLECode) {
        const payload: IMoveBPAccountPayload = {
          currentAccountCode: this.accountDetails?.salesOrgs[0]?.postingAccount,
          accountCode: this.accountDetails.accountNumber,
          newAccountCode: this.newARAccountCtrl.value.accountNumber,
          newCLE: this.selectCLECode
        };
        const obfPayload = setupOutboundFeedPayload(
          this.outboundFeedForm.form.getRawValue().obfs,
          this.feedType,
          this.accountDetails.accountNumber,
          this.accountDetails.name,
          this.newARAccountCtrl.value.accountNumber,
          this.selectCLECode
        );
        this.allowedTypesNewOutboundFeeds = combineOutboundFeedWithSameFeedFormatAndType(obfPayload);

        const inheritanceIds: string[] = Object.values(
          obfPayload.filter((item) => item.inheritanceId).map((x) => x.inheritanceId)
        );
        const inheritancePayload = {
          inheritanceIds: inheritanceIds,
          billingAccount: this.accountDetails.accountNumber,
          accountName: this.accountDetails.name,
          arAccount: this.newARAccountCtrl.value.accountNumber
        };
        if (this.toFindDuplicateOutboundFeed().length) {
          this.showErrorMessage = true;
        } else {
          this.showErrorMessage = false;
          this.store.dispatch(
            AccountActions.moveBPAccount(
              payload,
              obfPayload.filter((item) => !item.inheritanceId),
              inheritancePayload,
              partnerPayload
            )
          );
        }
      }
    } else {
      this.accountMoveForm.markAsTouched();
      this.outboundFeedForm.form.controls.obfs.markAllAsTouched();
    }
  }

  public allowMove(): void {
    this.allowChildAccountMove = true;
  }

  public checkArAccount(): boolean {
    return (
      !this.parentAccountsList ||
      (this.parentAccountsList?.items.length === 1 &&
        this.parentAccount &&
        this.parentAccountsList.items[0].accountNumber === this.parentAccount.accountNumber)
    );
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public toggleConfirm(): void {
    this.isConfirmed = !this.isConfirmed;
  }

  private subscribeTonewARAccountCtrl(): void {
    this.subs.add(
      this.newARAccountCtrl.valueChanges.subscribe(() => {
        if (
          this.newARAccountCtrl.valid &&
          this.selectCLECode &&
          this.selectCLECode !== this.accountDetails.legalEntityCode
        ) {
          this.showWarningPrompt = true;
        } else {
          this.showWarningPrompt = false;
        }
      })
    );
  }

  private loadParentAccount() {
    this.store.dispatch(AccountActions.loadParentAccount(this.accountDetails.salesOrgs[0].postingAccount));
    this.subs.add(
      this.store.select(getParentAccount).subscribe((parentAccountDetail) => {
        if (parentAccountDetail) {
          this.parentAccount = parentAccountDetail;
        }
      })
    );
  }

  private subscribeToSiblingAccountList(): void {
    this.subs.add(
      this.store.select(getChildAccounts).subscribe((siblingAccountsList) => {
        this.siblingAccountCount = siblingAccountsList.totalCount;
        // Count is taken as 2 - one parent itself, and another one current child account.
        if (this.siblingAccountCount > 2) {
          this.allowChildAccountMove = true;
        }
      })
    );
  }

  private subscribeToParentAccountList(): void {
    this.subs.add(
      this.store.select(getParentAccountsList).subscribe((parentAccountsList) => {
        const parentList = parentAccountsList.items;
        if (parentList && parentList.length > 0) {
          this.isSearching = true;
          const postingAccountNumber = this.accountDetails.salesOrgs[0]?.postingAccount;
          this.parentAccountsList = {
            loaded: true,
            items: parentList.map((value) => {
              return {
                ...value,
                accountDetail: `${value.accountNumber}, ${value.name}`
              };
            })
          };
          // Add Approved moved to CLE/AR number
          if (
            this.changeRequestRelatedLinks.approvedAccountInfo &&
            !this.parentAccountsList.items.find(
              (accountInfo) => accountInfo.accountNumber === this.changeRequestRelatedLinks.movedTo
            )
          ) {
            this.parentAccountsList.items.unshift(this.changeRequestRelatedLinks.approvedAccountInfo);
          }
          this.parentAccount = parentList.find((parent) => parent.accountNumber === postingAccountNumber);
          this.changeRequestRelatedLinks.approvedAccountInfo = this.parentAccountsList.items.find(
            (accountInfo) => accountInfo.accountNumber === this.changeRequestRelatedLinks.movedTo
          );
          if (!this.parentAccount) {
            this.loadParentAccount();
          }
        } else {
          this.parentAccountsList = {
            loaded: true,
            items: []
          };
          if (this.changeRequestRelatedLinks.approvedAccountInfo) {
            this.parentAccountsList.items.unshift(this.changeRequestRelatedLinks.approvedAccountInfo);
          }
        }
      })
    );
  }

  private subscribeToAccountDetails(): void {
    this.subs.add(
      this.store.select(getAccountDetails).subscribe((accountDetails) => {
        if (accountDetails) {
          this.accountDetails = accountDetails;
          let query: any = {};

          if (accountDetails.accountGroup === AccountGroup.INM6 || accountDetails.accountGroup === AccountGroup.IM17) {
            this.parentAccountGroup.push(AccountGroup.INM7);
            this.parentAccountGroup.push(AccountGroup.IM15);
            this.parentAccountGroup.push(AccountGroup.IM16);
            query = {
              ...query,
              accountGroup: this.parentAccountGroup,
              multiSearch: true
            };
          }
          if (accountDetails.accountGroup === AccountGroup.INM1 && !accountDetails.post) {
            this.parentAccountGroup.push(AccountGroup.INM1);
            query = {
              ...query,
              accountGroup: this.parentAccountGroup
            };
          }
          this.moveBPAccountBasedonRole(query, accountDetails);
          const postingAccount = this.accountDetails.salesOrgs[0]?.postingAccount;
          const siblingAccountQuery: IAccountQuery = {
            offset: 0,
            limit: 1
          };
          this.store.dispatch(AccountActions.childAccountsLoad(siblingAccountQuery, postingAccount));
          this.newARAccountCtrl.setValidators([
            Validators.required,
            this.invalidAccountSelection(postingAccount, this.accountDetails)
          ]);
          // active child account need approval
          this.changeRequestRelatedLinks.needAccountMoveRequest = this.accountDetails.status == AccountStatus.ACTIVE;
        }
      })
    );
  }

  private moveBPAccountBasedonRole(query, accountDetails: IAccountDetails) {
    // Credit team need to know the BP account moved to diferent CLE, even user has permission to move AR to dif CLE.
    if (this.canMoveARAccount && this.changeRequestRelatedLinks.hasApprovedMovedToAccount) {
      query = {
        ...query,
        accountNumber:
          this.changeRequestRelatedLinks.latestChangeRequestStatus !== IChangeRequestStatus.SEND_BACK
            ? this.changeRequestRelatedLinks.movedTo
            : null
      };
      this.store.dispatch(AccountActions.loadParentAccounts(query));
    } else if (this.canMoveBPAccount) {
      this.store.dispatch(
        AccountActions.loadParentAccountsUnderSameCLE({
          ...query,
          legalEntityCode: accountDetails.legalEntityCode
        })
      );
    }
  }

  private invalidAccountSelection(parentAccountNumber: string, currentAccount): ValidatorFn {
    return (control: UntypedFormControl) => {
      let validation = null;
      this.showUnmatahedSalesOrgs = false;

      if (control.value) {
        const newARCodeList = control.value.salesOrgs ? control.value.salesOrgs.map((x) => x.code) : null;
        const currentCodeList = currentAccount.salesOrgs ? currentAccount.salesOrgs.map((x) => x.code) : null;
        const accountNumber = control.value.accountNumber;
        this.selectCLECode = control.value.legalEntityCode;
        if (accountNumber === parentAccountNumber) {
          validation = {
            sameFolderSelected: parentAccountNumber
          };
        } else if (
          accountNumber === this.changeRequestRelatedLinks?.movedTo &&
          this.changeRequestRelatedLinks.latestChangeRequestStatus === IChangeRequestStatus.SEND_BACK
        ) {
          validation = {
            pattern: this.changeRequestRelatedLinks?.movedTo
          };
        } else if (!newARCodeList || !currentCodeList || !currentCodeList.every((v) => newARCodeList.includes(v))) {
          this.showUnmatahedSalesOrgs = !currentCodeList.every((v) => newARCodeList.includes(v));
          this.requiredNewSalesCode = difference(currentCodeList, newARCodeList);
          validation = {
            pattern: parentAccountNumber
          };
        }
        if (validation === null) {
          this.pullARAccountOBFs();
        }
      }
      return validation;
    };
  }

  public searchDealer(search: string): void {
    this.store.dispatch(AccountActions.loadDealerList(search));
  }

  public searchShipOwner(search: string): void {
    this.store.dispatch(AccountActions.loadShipOwnerList(search));
  }

  public searchContractParty(search: string): void {
    this.store.dispatch(AccountActions.loadContractPartnerInfo(search));
  }

  public searchManagementParty(search: string): void {
    this.store.dispatch(AccountActions.loadManagementCompanyInfo(search));
  }

  private updatePartnerInVesselAccount() {
    // get partner function value in INM6/IM17
    if (
      this.accountDetails.accountGroup === AccountGroup.INM6 ||
      this.accountDetails.accountGroup === AccountGroup.IM17
    ) {
      this.accountMoveForm.controls.dealerCode.setValidators([Validators.required]);
      this.accountMoveForm.controls.contractParty.setValidators([Validators.required]);
      this.accountMoveForm.controls.managementParty.setValidators([Validators.required]);
      this.subs.add(
        this.store.select(getPartnerInfoByAccount).subscribe((partnerList) => {
          this.partnerList = partnerList;
        })
      );

      this.getDealerList();
      this.getShipOwnerList();
      this.getContractPartyList();
      this.getManagementPartyList();

      this.searchDealer(undefined);
      this.searchShipOwner(undefined);
      this.searchContractParty(undefined);
      this.searchManagementParty(undefined);
    }
  }

  private getDisplayValue(accountInfo): any {
    if (accountInfo?.items) {
      let updatedAccountInfo;
      if (accountInfo.items.length > 0) {
        updatedAccountInfo = {
          ...accountInfo,
          items: accountInfo.items.map((value) => {
            return {
              accountDetail: `${value.accountNumber}, ${value.name}`
            };
          })
        };
      }
      return updatedAccountInfo;
    }
  }

  private updateAccountInfoList(partnerDefaultList: any, type: MoveBPAccountFields) {
    switch (type) {
      case MoveBPAccountFields.DealerCode:
        partnerDefaultList.items.push({
          accountDetail: `${this.partnerList.dealerCode}, ${this.partnerList.dealerCodeName}`
        });
        return partnerDefaultList;
      case MoveBPAccountFields.ContractParty:
        partnerDefaultList.items.push({
          accountDetail: `${this.partnerList.contractParty}, ${this.partnerList.contractPartyName}`
        });
        return partnerDefaultList;
      case MoveBPAccountFields.ManagementParty:
        partnerDefaultList.items.push({
          accountDetail: `${this.partnerList.managementParty}, ${this.partnerList.managementPartyName}`
        });
        return partnerDefaultList;
      case MoveBPAccountFields.ShipOwner:
        partnerDefaultList.items.push({
          accountDetail: `${this.partnerList.shipOwner}, ${this.partnerList.shipOwnerName}`
        });
        return partnerDefaultList;
      default:
        return partnerDefaultList;
    }
  }

  private getManagementPartyList() {
    this.subs.add(
      this.store.select(getManagementCompanyInfo).subscribe((managementPartyInfo) => {
        this.managementPartyList = this.getDisplayValue(managementPartyInfo);
        if (this.managementPartyList?.items && this.partnerList && this.partnerList.managementParty) {
          this.managementPartyList = this.managementPartyList.items.filter((item) =>
            item.accountDetail.includes(this.partnerList.managementParty)
          ).length
            ? this.managementPartyList
            : this.updateAccountInfoList(this.managementPartyList, MoveBPAccountFields.ManagementParty);
          this.patchPartnerInfo(
            this.managementPartyCtrl,
            this.managementPartyList,
            this.partnerList.managementParty,
            this.managementPartyListFirstLoad
          );
          this.managementPartyListFirstLoad = false;
        }
      })
    );
  }

  public getTooltipMessage(): string {
    const successMessage = getStaticContent(
      'accounts.details.child_account_move.invalid_combination',
      staticContent
    ).replace('{salesCode}', this.requiredNewSalesCode);

    return this.showUnmatahedSalesOrgs ? successMessage : null;
  }

  private getContractPartyList() {
    this.subs.add(
      this.store.select(getContractPartnerInfo).subscribe((contractPartyList) => {
        this.contractPartyList = this.getDisplayValue(contractPartyList);
        if (this.contractPartyList?.items && this.partnerList && this.partnerList.contractParty) {
          this.contractPartyList = this.contractPartyList.items.filter((item) =>
            item.accountDetail.includes(this.partnerList.contractParty)
          ).length
            ? this.contractPartyList
            : this.updateAccountInfoList(this.contractPartyList, MoveBPAccountFields.ContractParty);
          this.patchPartnerInfo(
            this.contractPartyCtrl,
            this.contractPartyList,
            this.partnerList.contractParty,
            this.contractPartyListFirstLoad
          );
          this.contractPartyListFirstLoad = false;
        }
      })
    );
  }

  private getDealerList() {
    this.subs.add(
      this.store.select(getDealer).subscribe((dealerList) => {
        this.dealerList = this.getDisplayValue(dealerList);
        if (this.dealerList?.items && this.partnerList && this.partnerList.dealerCode) {
          this.dealerList = this.dealerList.items.filter((item) =>
            item.accountDetail.includes(this.partnerList.dealerCode)
          ).length
            ? this.dealerList
            : this.updateAccountInfoList(this.dealerList, MoveBPAccountFields.DealerCode);
          this.patchPartnerInfo(
            this.dealerCtrl,
            this.dealerList,
            this.partnerList.dealerCode,
            this.dealerListFirstLoad
          );
          this.dealerListFirstLoad = false;
        }
      })
    );
  }

  private getShipOwnerList() {
    this.subs.add(
      this.store.select(getShipOwner).subscribe((shipOwnerList) => {
        this.shipOwnerList = this.getDisplayValue(shipOwnerList);
        if (this.shipOwnerList?.items && this.partnerList && this.partnerList.shipOwner) {
          this.shipOwnerList = this.shipOwnerList.items.filter((item) =>
            item.accountDetail.includes(this.partnerList.shipOwner)
          ).length
            ? this.shipOwnerList
            : this.updateAccountInfoList(this.shipOwnerList, MoveBPAccountFields.ShipOwner);
          this.patchPartnerInfo(
            this.shipOwnerCtrl,
            this.shipOwnerList,
            this.partnerList.shipOwner,
            this.shipOwnerListFirstLoad
          );
          this.shipOwnerListFirstLoad = false;
        }
      })
    );
  }

  private patchPartnerInfo(formControlName: any, updatedPartnerList: any, partnerName: any, firstLoad: boolean): void {
    if (firstLoad) {
      formControlName.patchValue(
        updatedPartnerList.items.filter((item) => item.accountDetail.includes(partnerName))[0]
      );
    }
  }

  private getChangedPartnerPayload(): any {
    const touchedList = Object.entries(this.accountMoveForm.controls).filter(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([_, value]) => value.touched
    );
    const updatedList = Object.fromEntries(touchedList);
    // remove AR account in the update functional partner payload
    delete updatedList.newARAccount;
    if (Object.entries(updatedList).length) {
      return this.getPartnerAccountPayload(updatedList);
    }
  }

  private getPartnerAccountPayload(updatedList: any): any {
    return Object.keys(updatedList).reduce((acc, key) => {
      acc[key] = updatedList[key].value ? updatedList[key].value.accountDetail.split(',')[0] : '';
      return acc;
    }, {});
  }

  public expandPanelAction(row: any): void {
    this.expandedPanel[row] = !this.expandedPanel[row];
  }

  public getTooltipText(): string {
    if (
      (this.outboundFeedForm?.form?.valid || this.outboundFeedForm?.form?.disable) &&
      !this.checkMandatoryOutboundFeedsValue()
    ) {
      return TOOLIP_MESSAGE;
    }
  }

  // Account must have credit note and invoice OBFs.
  public checkMandatoryOutboundFeedsValue(): boolean {
    return (
      this.outboundFeedForm?.form
        ?.getRawValue()
        ?.obfs.some((vendor) => vendor['feedFormat'] === MandatoryOutboundFeed.INVOICE) &&
      this.outboundFeedForm?.form
        ?.getRawValue()
        ?.obfs.some((vendor) => vendor['feedFormat'] === MandatoryOutboundFeed.CREDIT_NOTE)
    );
  }

  public getFeedTypeList(): void {
    this.subs.add(
      this.store.select(getOBFFeedTypeOptions).subscribe((feedTypeList) => {
        if (feedTypeList) {
          this.feedType = feedTypeList;
        }
      })
    );
  }

  // If AR account doesn't have OBFs, then portal doesn't need to show the checkbox.
  private pullARAccountOBFs() {
    const queryParams = {
      accountNumber: this.newARAccountCtrl.value.accountNumber,
      status: OutboundFeedStatus.ACTIVE,
      legalEntityCode: this.selectCLECode
    };
    this.store.dispatch(AccountActions.pullARAccountOBFS(queryParams));
  }

  public toFindDuplicateOutboundFeed(): any[] {
    let duplicates = [];
    Object.keys(this.allowedTypesNewOutboundFeeds).forEach((key) => {
      if (this.allowedTypesNewOutboundFeeds[key].length > 1) {
        const combineDuplicateOutboundFeed = (
          Array.from(new Set(this.allowedTypesNewOutboundFeeds[key].map(JSON.stringify))) as any
        ).map(JSON.parse);

        duplicates =
          !duplicates?.length && combineDuplicateOutboundFeed.length === this.allowedTypesNewOutboundFeeds[key].length
            ? []
            : combineDuplicateOutboundFeed;
      }
    });
    return duplicates;
  }

  public getApprovedMovedTo(): void {
    this.subs.add(
      this.store.select(getMoveToCLEOrAccountDetails).subscribe((approvedMovedTo) => {
        if (approvedMovedTo) {
          if (approvedMovedTo.count) {
            this.changeRequestRelatedLinks.hasApprovedMovedToAccount =
              approvedMovedTo?.assets[0]?.movedTo !== this.parentAccount?.accountNumber;
            this.changeRequestRelatedLinks.movedTo = approvedMovedTo?.assets[0]?.movedTo;
          } else {
            this.changeRequestRelatedLinks.hasApprovedMovedToAccount = false;
          }
        }
      })
    );
  }

  public requestMoveApproval(): void {
    this.bsModalRef.hide();
    this.store.dispatch(
      ChangeRequestActions.openChangeRequestModal(
        this.changeRequestRelatedLinks.latestChangeRequestStatus === IChangeRequestOptions.SEND_BACK
          ? IChangeRequestOptions.RESUBMIT
          : IChangeRequestOptions.CREATE,
        IChangeRequestEntityType.ACCOUNT,
        this.setMoveChangeRequestPayload(),
        this.changeRequestRelatedLinks.latestChangeRequestStatus === IChangeRequestOptions.SEND_BACK
          ? this.changeRequestRelatedLinks.latestChangeRequestId
          : null
      )
    );
  }

  public setMoveChangeRequestPayload(): IChangeRequestPayloadObject {
    return this.changeRequestRelatedLinks.latestChangeRequestStatus !== IChangeRequestOptions.SEND_BACK
      ? {
          changeRequest: {
            requestType: IChangeRequestType.MOVE,
            requestSummary: IChangeRequestSummary.MOVE_BILLING,
            entityType: IChangeRequestEntityType.ACCOUNT,
            assignmentGroup: IChangeRequestAssignmentGroup.CREDIT,
            entityId: this.accountDetails.accountNumber,
            entityName: this.accountDetails.name,
            accountGroup: this.accountDetails.accountGroup,
            movedFrom: this.accountDetails.salesOrgs[0].postingAccount,
            legalEntityCode: this.accountDetails.legalEntityCode,
            legalEntityName: this.accountDetails.legalEntityName
          }
        }
      : {
          changeRequest: {
            requestType: IChangeRequestType.MOVE
          }
        };
  }

  public getLinkLabel(): void {
    this.subs.add(
      this.store.select(getChangeRequestList).subscribe((changeRequest) => {
        if (changeRequest?.items.length) {
          this.changeRequestRelatedLinks.message =
            changeRequest?.items[0].status === IChangeRequestStatus.SEND_BACK
              ? RESUBMIT_MOVE_CHANGE_REQUEST
              : CREATE_MOVE_CHANGE_REQUEST;
          this.changeRequestRelatedLinks.latestChangeRequestStatus = changeRequest?.items[0].status;
          this.changeRequestRelatedLinks.latestChangeRequestId = changeRequest?.items[0]._id;
          this.changeRequestRelatedLinks.movedTo =
            !this.changeRequestRelatedLinks.movedTo &&
            this.changeRequestRelatedLinks.latestChangeRequestStatus === IChangeRequestStatus.SEND_BACK
              ? changeRequest?.items[0]?.movedTo
              : this.changeRequestRelatedLinks.movedTo;
          this.changeRequestRelatedLinks.message = this.changeRequestRelatedLinks.message.replace(
            '{MovedTo}',
            this.changeRequestRelatedLinks.movedTo
          );
        }
      })
    );
  }
}
